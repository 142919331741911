import gql from "graphql-tag";

const CHALLENGE_HOMEPAGE = gql`query($challengesFirst: Int) {
  challenges(last: $challengesFirst) {
    edges {
      node {
        id
        pk
        minDescription
        title
        image
      }
    }
  }
}`

const CHALLENGES_FIELDS = `edges {
      cursor
      node {
        id
        preTitle
        title
        minDescription
        challengeCategory {
          pk
          title
        }
        image
        solvedProblems{
          edgeCount
        }
        chapterSet {
          edgeCount
          edges {
            node {
              problemSet {
                edgeCount
              }
            }
          }
        }
        pk
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  `

const CHALLENGES = gql`query ($challengesFirst: Int, $challengesBefore: String, $challengesAfter: String) {
  challenges(first: $challengesFirst, before: $challengesBefore, after: $challengesAfter) {
    ${CHALLENGES_FIELDS}
  }
}`;

const ENOLLED_CHALLENGES = gql`query ($challengesFirst: Int, $challengesBefore: String, $challengesAfter: String) {
  challenges(first: $challengesFirst, before: $challengesBefore, after: $challengesAfter) {
    ${CHALLENGES_FIELDS}
  }
}`;


const CHALLENGE_OVERVIEW = gql`query($challengePk: Int, $chapterSetFirst: Int, $problemSetFirst: Int) {
  challenge(pk: $challengePk) {
    id,
    title,
    preTitle,
    description,
    createdDate,
    minDescription,
    chapterSet(first: $chapterSetFirst) {
      edges {
        node {
          pk
          problemSet(first: $problemSetFirst) {
            edges {
              node {
                pk,
                title,
              }
            }
          }
        }
      }
    }
  }
}`

const CHALLENGE_CHAPTERS = gql`query($challengePk: Int) {
  challenge(pk: $challengePk) {
    id,
    chapterSet {
      edges {
        node {
          pk
          title,
          problemSet {
            edges {
              node {
                pk,
                title,
                myAnswer{
                  solved
                }
              }
            }
          }
          numberOfProblems
        }
      }
    }
  }
}`

const CATEGORY = gql`query {
  categories {
    edges {
      node {
        pk
        title
      }
    }
  }
}`;

export {
  CHALLENGE_HOMEPAGE,
  CHALLENGES,
  CHALLENGE_OVERVIEW,
  CHALLENGE_CHAPTERS,
  ENOLLED_CHALLENGES,
  CATEGORY,
}
