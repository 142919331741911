<template>
 <v-navigation-drawer app v-model="show" width="350px"   :mini-variant.sync="mini" :permanent="editor" hide-overlay>
      <v-list-item v-if="mini">
        <v-list-item-avatar :class="{'custom-avatar' : !mini, 'ml-0' : mini}" tile @click="mini=false">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-menu
              </v-icon>
            </template>
            <span>Maximize Side Menu</span>
          </v-tooltip>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="blue--text" v-else>
        <v-list-item-icon @click="$router.push('/challenges')">
          <v-icon class="blue--text">mdi-arrow-left-bold </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="ml-2" @click="$router.push('/challenges')">Back to Challenges</v-list-item-title>
        <v-list-item-action @click="mini=true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-fullscreen-exit
              </v-icon>
            </template>
            <span>Minimize Side Menu</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>

      <v-list class="mt-1" v-if="!mini">
        <div
          v-for="(chapter, index) in challenge"
          :key="index"
        >
          <v-list-group
            :value="false"
            class="px-3 nav-links"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-h6 font-weight-medium">{{ chapter.node.title }}</v-list-item-title>
            </template>
              
            <v-list-item
              v-for="(problem, i) in chapter.node.problemSet.edges"
              :key="i"
              link
              :to="getNext(chapter.node.problemSet.edges, i) ? `/challenge/${$route.params.challengeId}/${chapter.node.pk}/${problem.node.title}/${problem.node.pk}/${getNext(chapter.node.problemSet.edges, i)}` : `/challenge/${$route.params.challengeId}/${chapter.node.pk}/${problem.node.title}/${problem.node.pk}`"
              color="primary"
              class="ml-5"
              @click="mini=true;"
            >
              <v-list-item-icon>
                <v-icon v-if="problem.node.myAnswer" :color="problem.node.myAnswer.solved ? 'success': 'warning'"> {{ problem.node.myAnswer.solved ? 'mdi-checkbox-marked' : 'mdi-close-box-outline' }}</v-icon>
                <v-icon v-else> mdi-square-rounded </v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="problem.node.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-divider class="pt-0" v-if="index < challenge.length"></v-divider>
        </div>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { CHALLENGE_CHAPTERS } from '@/queries/challenge';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'sideBar',
  mixins: [colorMxn],
  data() {
    return {
      show: false,
      mini: false,
      challenge: [
        {
            "node": {
              "title": "Welcome to the Eigensolvers Quantum School Hackathon. In this notebook are 4 problems covering all t",
              "problemSet": {
                "edges": [
                  {
                    "node": {
                      "pk": 1,
                      "title": "Problem 1: Inter-Basis Computation"
                    }
                  },
                  {
                    "node": {
                      "pk": 2,
                      "title": "Problem 2: Correlated Qubits"
                    }
                  },
                  {
                    "node": {
                      "pk": 3,
                      "title": "Problem 3: The Quantum Oracle"
                    }
                  },
                  {
                    "node": {
                      "pk": 4,
                      "title": "Problem 4: Fun with Quantum"
                    }
                  }
                ]
              }
            }
          }
      ],
    }
  },
  computed: {
    ...mapGetters(['getUpdateChallengeObject', 'getNextPreviousObject']),
    editor() {
      return this.$route.name === 'ProblemEditor' || this.$route.name === 'CodeEditor'  ;
    },
  },
  watch: {
    editor(val) {
      this.show = val;
    },
    getUpdateChallengeObject(val) {
      this.updateChallenge(val.chapterId, val.problemId, val.value);
    }
  },
  mounted() {
    this.fetchChallenge();
    this.show = this.editor;
    this.$root.$on('updateChallenge', this.updateChallenge);
  },
  methods: {
    ...mapMutations(['setNextPreviousObject', 'setSideBarChallenge']),
    fetchChallenge() {
      this.$apollo.query({
        query: CHALLENGE_CHAPTERS,
        variables: {
          'challengePk': parseInt(this.$route.params.challengeId),
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        this.setSideBarChallenge(response.data.challenge.chapterSet.edges);
        this.challenge = response.data.challenge.chapterSet.edges;
      })
    },
    updateChallenge(response) {
      const chapterSet = this.challenge.filter((chapter) => chapter.node.pk === response.chapterId);
      const chapter = chapterSet[0];
      const indexOfChapter = this.challenge.indexOf(chapter);
      const problemSet = chapter.node.problemSet.edges.filter((problem) => problem.node.pk === response.problemId);
      const problem = problemSet[0];
      const indexOfproblem = chapter.node.problemSet.edges.indexOf(problem);   
      
      const myAnswer = this.challenge[indexOfChapter].node.problemSet.edges[indexOfproblem].node.myAnswer;
      if (myAnswer) {
        this.challenge[indexOfChapter].node.problemSet.edges[indexOfproblem].node.myAnswer.solved = response.value;
        return;
      }

      this.challenge[indexOfChapter].node.problemSet.edges[indexOfproblem].node.myAnswer = {
        solved: response.value,
      };

    },
    getNext(chapter, index) {
      const result = chapter.length > (index + 1) ? chapter[index + 1].node.pk: '';
      return result;
    },
    getprevious(chapter, index) {
      const result = index !== 0  ? chapter[index - 1].node.pk: '';
      return result;
    },
    setNext(chapter, index) {
      this.mini = true;
      const data = {
        previous: this.getNext(chapter, index) ? `/challenge/${$route.params.challengeId}/${chapter.node.pk}/${chapter[index + 1].node.title}/${chapter[index + 1].node.pk}/${getNext(chapter, index)}` : null,
        next: this.getprevious(chapter, index) ? `/challenge/${$route.params.challengeId}/${chapter.node.pk}/${chapter[index - 1].node.title}/${chapter[index - 1].node.pk}/${getNext(chapter, index)}` : null,
        
      }
      localStorage.setItem('nextObj', data);
      this.setNextPreviousObject(data);
    }
  }
}
</script>


